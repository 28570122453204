
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Stepper,
  Dialog,
  Checkbox,
  Field,
  List,
} from "vant";
import CouponItem from "./CouponItem.vue";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    CouponItem,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [Field.name]: Field,
    [List.name]: List,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class MyCoupons extends Mixins(Mixin) {
  public checked = false;
  public active = 0;
  public couponList: Array<member.CouponRes> = [];
  public text = "";
  addCoupon = false;
  key = 0;
  value = "";
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  cardInputFormatter(value: string): string {
    let val = "";
    if (value.length > 21) {
      val = value.replace(/[^\w\.\/]/gi, "").slice(0, 19);
      this.value = val;
    } else {
      val = value.replace(/[^\w\.\/]/gi, "");
      this.value = val;
    }
    return val;
  }
  getMyCoupons(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    let couponsStatus: "UNUSED" | "EXPIRED" | "USED" = "UNUSED";
    if (this.active === 0) {
      couponsStatus = "UNUSED";
      this.text = "暂时没有可以使用的优惠券";
    } else if (this.active === 1) {
      couponsStatus = "USED";
      this.text = "暂时没有已使用的优惠券";
    } else if (this.active === 2) {
      couponsStatus = "EXPIRED";
      this.text = "暂时没有已过期的优惠券";
    }

    let params = {
      couponsStatus: couponsStatus,
      pageNum: this.page.current,
      pageSize: this.page.size,
    };
    this.$api.memberApi.coupons.myCoupons(params, ({ data }) => {
      if (this.page.current === 1) {
        this.couponList = data.records!;
      } else {
        this.couponList = this.couponList.concat(data.records!);
      }

      this.list.loading = false;
      this.list.finished = data.current! >= data.pages!;
    });
  }
  getCoupons(name: string, title: string): void {
    this.couponList = [];
    if (title === "未使用") {
      this.getMyCoupons(true);
    } else if (title === "已使用") {
      this.getMyCoupons(true);
    } else if (title === "已过期") {
      this.getMyCoupons(true);
    }
  }
  rendered(name: string, title: string): void {
    this.getCoupons(name, title);
  }
  onClickLeft(): void {
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
  onClickRight(): void {
    let name = "优惠券规则";
    let type = "COUPON";
    if (
      this.ChannelModule.channel === "plat_h5" ||
      this.AppSource == "harmonyOS"
    ) {
      this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
    } else {
      window.location.href = `https://pth5.cs.yuboya.cn/index.html#/personal-data/notice?name=${name}&type=${type}`;
    }
  }
  AddCoupon(): void {
    if (this.value) {
      monitorEvent("MyOffer_ClickAdd", "优惠券列表点击添加"); // 埋点：优惠券列表，优惠券列表点击添加
      this.$api.goodApi.coupon.exchangeCoupon(
        this.value,
        ({ data }) => {
          if (data) {
            monitorEvent("MyOffer_AddSuccess", "成功添加一张优惠券"); // 埋点：优惠券列表，成功添加一张优惠券
            this.$toast("优惠券激活成功");
            this.addCoupon = false;
            this.key++;
          }
        },
        (error) => {
          error && this.$toast(error);
        }
      );
    } else {
      this.$toast("请输入优惠券码");
    }
  }
  closePopup(): void {
    this.value = "";
  }
}
